<template>
	<div class="w-full">
		<div class="container">
			<div class="table-responsive w-100">
				<table class="table">
					<thead>
						<tr>
							<th>Calendar</th>
							<th>Sender</th>
							<th>Recipient</th>
							<th>Time</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="request in requests"
							:key="request.id"
							class="table-row"
						>
							<td>
								{{ request.event.calendars[0].name }}
							</td>
							<td class="text-nowrap">
								{{ getValue(request, 'sender.full_name') }}
							</td>
							<td>
								{{ getValue(request, 'recipient.full_name') }}
							</td>
							<td>
								{{ request.starts_at | toDateTime }}
								-
								{{ request.ends_at | toDateTime }}
							</td>
							<td>
								{{ request.status.name | ucFirst }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { orderBy, get } from 'lodash'
import { mapGetters } from 'vuex'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		...mapGetters({
			partner: 'partners/active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * using loadsh get method to getch details of the request api
		 *
		 */
		getValue(obj, path, defaultValue = '-') {
			return get(obj, path, defaultValue)
		},
		/**
		 * Fetch cover my call request for the given partner.
		 *
		 * @return {Promise}
		 */
		fetchCoverMyCallRequest() {
			return this.$api
				.partners(this.partner.id)
				.requests()
				.get()
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Requests',

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		try {
			const response = await this.fetchCoverMyCallRequest()

			this.requests = orderBy(response.get('data', []), 'status.name', [
				'desc',
			])
		} catch (e) {
			this.$alert.error('default.error')
		}
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			requests: [],
		}
	},
}
</script>
